@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  .wp-caption-text{
    @include PC{
      line-height: 28px;
    }
  }
  @include SP() {
    width: 100% !important;
    margin-bottom: 38px !important;
  }
}

.wp-caption.alignnone {
  margin: 0 0 10px;
}

.wp-caption.alignleft {
  margin: 0 0 10px
}

.wp-caption.alignright {
  margin: 0 0 10px
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  @include SP() {
    margin-top: 15px;
  }
}

// CUSTOM

.leftCol {
  width: 70%;
  float: left;
}
.rightCol {
  width: 30%;
  float: right;
}

.cmsContent {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
  @include clearfix;
  ul,
  ol {
    padding-left: 20px;
    margin-bottom: 46px;
    li{
      @include font(14,24/14,.08em,700);
      margin-bottom: 12px;
    }
    @include SP{
      margin-bottom: 28px;
    }
  }
  ul {
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
        &:before{
          display: none;
        }
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
        &:before{
          display: none;
        }
      }
    }
    li {
      position: relative;
      &:before{
        content: '';
        width: 8px;
        height: 8px;
        background: $neon_green;
        position: absolute;
        top: 8px;
        left: -20px;
        border-radius: 50%;
      }
    }
  }
  ol {
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
        &:before{
          display: none;
        }
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
        &:before{
          display: none;
        }
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
        &:before{
          display: none;
        }
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
        &:before{
          display: none;
        }
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
        &:before{
          display: none;
        }
      }
    }
    counter-reset: counter;
    li {
      position: relative;
      &:before{
        counter-increment: counter;
        content: counter(counter)".";
        @include font(16,28/16,.1em,500);
        @include barlow;
        color: $blue;
        position: absolute;
        top: -1px;
        left: -20px;
      }
    }
  }
  p {
    @include font(16,32/16,.05em,700);
    margin-bottom: 45px;
    @include SP{
      @include font(14,24/14,.05em,700);
      margin-bottom: 37px;
    }
  }
  iframe {
    max-width: 100% !important;
  }
  img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    width: auto;
  }
  i,em {
    font-style: italic;
  }
  * {

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  span[style*="text-decoration: underline"],
  span[style*="text-decoration:underline"] {

    background-image: -webkit-linear-gradient(30deg, $neon_yellow 0%, $neon_yellow 100%);
    background-image: -o-linear-gradient(30deg, $neon_yellow 0%, $neon_yellow 100%);
    background-image: linear-gradient(30deg, $neon_yellow 0%, $neon_yellow 100%);
    background-repeat: no-repeat;
    background-size: 100% 7px;
    background-position: 0 100%;
    text-decoration: none !important;
    font-style: normal;
  }
  a {
    text-decoration: underline;
    color: $blue;
  }
  blockquote {
    padding: 40px 38px;
    background: #F7F7F7;
    border-radius: 10px;
    position: relative;
    margin-bottom: 62px;
    @include SP{
      padding-bottom: 27px;
      margin-bottom: 40px;
    }
    &:before{
      content: '';
      width: 41px;
      height: 32px;
      background: url(../img/common/icon/ico_blockquote.svg) 0 0/ 100% auto no-repeat;
      position: absolute;
      top: 18px;
      left: 21px;
    }
    p{
      @include font(14,26/14,.08em,700);
      position: relative;
    }
  }
  h2 {
    @include font(20,36/20,.1em,700);
    padding: 32px 27px 33px;
    border-bottom: 2px solid $blue;
    margin-bottom: 42px;
    background: #fff url(../img/common/icon/dots.svg) left top/4px 4px repeat;
    @include SP{
      @include font(18,30/18,.1em,700);
      margin: 0 -20px 35px;
      padding: 18px 23px 26px;
    }
  }
  h3 {
    @include font(16,28/16,.1em,700);
    padding: 20px 27px;
    background: $baby_blue;
    margin-bottom: 33px;
    @include PC{
      border-radius: 10px;
    }
    @include SP{
      line-height: 26px;
      margin: 0 -20px 22px;
      padding: 17px 23px 20px;
    }
  }
  h4 {
    @include font(16,32/16,.1em,700);
    color: $blue;
    margin-bottom: 38px;
    @include SP{
      line-height: 28px;
      margin-bottom: 24px;
    }
  }

  //common image caption show in two columns
  .setIn{
    @include PC{
      @include flexbox;
      @include flex-wrap;
      &:nth-of-type(2n){
        img{
          margin-left:auto !important;
          margin-right: 0 !important;
        }
      }
    }
  }
  .wp-caption.alignnone{
    @include PC{
      display: inline-flex;
      flex-direction: column;
      margin: 0 0 63px;
      width: auto !important;
      img{
        margin-bottom: 17px ;
      }
      p{
        margin: 0 6px;
      }
    }
  }
  .wp-caption-text{
    display: inline-block;
    &.setW{
      @include PC{
        width: 350px;
      }
      @include min-screen(1140px) {
        width: 408px;
      }
    }
  }
  img{
    &.setW{
      @include PC{
        max-width: 350px;
        width: auto;
        height: 612px;
        object-fit: cover;
      }
      @include min-screen(1140px) {
        max-width: 415px;
      }
    }
  }
  p.setIn{
    a{
      &:last-child{
        img.setW{
          margin-right: 0 !important;
        }
      }
    }
    img{
      &:not(:first-child){
        @include SP{
          margin-top: 26px;
        }
      }
    }
  }
  a.setIn{
    width: 50%;
  }
}

.pagingDt {
  position: relative;
  width: 80%;
  margin: 95px auto;
  font-size: 14px;
}
.pagingDt a {
  text-decoration: underline;
}
.pagingDt .prev {
  background: url(../../img/blog/ico_prev.png) no-repeat left center;
  width: 145px;
  float: left;
  padding-left: 20px;
}
.pagingDt .next {
  background: url(../../img/blog/ico_next.png) no-repeat right center;
  width: 145px;
  float: right;
  padding-right: 20px;
}
.pagingDt .list {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -54px;
  margin-top: -9px;
}
