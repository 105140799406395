
input, textarea{
  appearance: none;
  box-shadow: none;
  border: none;
}
.wcm {
    max-width: 1264px;
    @include SP {
      &.ctc {
        padding: 0 11px;
      }
    }
    .contactform,
    .confirmform {
      max-width: 1224px;
      padding: 0 20px;
      margin: 0 auto;
      background: #fff;
      border-radius: 19px;
      @include SP {
        padding: 0 8px;
      }
      .formBlock {
        max-width: 950px;
        margin: 0 auto;
        padding: 89px 0 0;

        img {
          display: block;
          width: 100%;
          margin: 0 auto;
          max-width: 729px;

          @include SP {
            max-width: 100%;
          }
        }
        @include SP {
          padding: 39px 0;
        }
      }

      .txtContact {
        text-align: center;
        @include font(16, 28/16, 0.08em, bold);
        margin-bottom: 60px;
        @include SP {
          @include font(14, 26/14, 0.08em, bold);
          margin-bottom: 31px;
        }
      }

      .privacy-ctc {
        @include font(14, 26/14, 0.08em, bold);
        &__lst {
          @include PC{
            width: 100%;
          }
          margin-top: 7px;
          li {
            padding-left: 23px;
            position: relative;
            margin-bottom: 15px;
            &::before {
              position: absolute;
              content: "";
              width: 6px;
              height: 6px;
              background-color: #002e50;
              border-radius: 50%;
              left: 8px;
              top: 10px;
            }
            a {
              text-decoration: underline;
              color: #0088db;
            }
          }
        }
      }
      .checkCo {
        width: 100%;
        margin:86px auto 0;
        text-align: center;
        @include SP{
          margin:50px auto 0;
        }
        label{
          max-width: 464px;
          position: relative;
          background: #FDFDED;
          border:1px solid #BBCBD9;
          border-radius: 5px;
          padding: 16px 0;
          display: block;
          margin:0 auto;
          input{
            position: absolute;
            top:0;left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            &:checked +.checkmark:after{opacity: 1}
          }
          .checkmark{
            display: block;
            position: relative;
            color:#434343;
            @include font(16,calc(34/16),0.08em,bold);
            @include SP{
              @include font-size(15);
              padding-left:20px
            }
            &:before,&:after{
              content:"";
              position: absolute;
              width: 24px;
              height: 24px;
              left: 30px;
              top:calc(50% - 12px);
              @include transition_c(all 0.3s);
              @include SP{
                width: 21px;
                height: 21px;
                top: calc(50% - 10.5px);
              }
            }
            &:before{
              background: #fff;
              border: 1px solid #BBCBD9;
            }
            &:after{
              opacity: 0;
              background: url(../../img/common/icon/ico_check.svg) center/100% 100% no-repeat;
            }
          }
        }
      }
      .btnConfirm {
        text-align: center;
        button {
          position: relative;
          width: 100%;
          max-width: 441px;
          margin: 59px auto 21px;
          background-color: #e6fc3c;
          border: 1px solid #e6fc3c;
          padding: 16px 0;
          border-radius: 50px;
          transition: all 0.3s;
          span {
            @include font(18, 34/18, 0.08em, bold);
            @include zen;
            color: #0088db;
            display: block;
            .ico {
              width: 24px;
              height: 24px;
              display: inline-block;
              position: absolute;
              border: 2px solid #0088db;
              right: 20px;
              top: calc(50% - 12px);
              transition: all 0.3s;
              &:before {
                width: 8px;
                height: 2px;
                margin-right: 5px;
                bottom: calc(50% - 2px);
                @include transition_c(0s);
              }
              &:after {
                border-bottom: 6px solid currentColor;
                border-right: 6px solid transparent;
                bottom: calc(50% - 2px);
                @include transition_c(0s);
              }
            }
          }
          @include min-screen(1025px) {
            &:hover {
              background-color: #0088db;
              border: 1px solid #0088db;
              span {
                color: #fff;
                .ico {
                  border-color: #fff;
                }
              }
            }
          }
          @include SP {
            margin-top: 31px;
          }
        }
      }
      .mailCo {
        text-align: center;
        @include font(14, 26/14, 0.08em, bold);
        color: #434343;
        margin-top: 61px;
        @include SP {
          @include font(13, 23/13, 0.08em, bold);
          margin-top: 41px;
        }
        a {
          color: #0088db;
          text-decoration: underline;
          transition: all 0.3s;
          @include min-screen(1025px) {
            &:hover {
              text-decoration: none;
              opacity: 1;
            }
          }
        }
      }
      .tableContact em,
      .txtContact em {
        color: #fff;
        font-style: normal;
        background-color: #9ae02a;
        @include font(14, 32/14, 0.08em, bold);
        border-radius: 6px;
        padding: 3px 12px;
        margin-right: 15px;
        @include SP {
          padding: 2px 10px;
          margin-right: 10px;
        }
        &.option {
          background-color: #002e50;
        }
      }

      .tableContact {
        border: 1px solid #002e50;
        @include add_prefix(border-radius, 10px);
        width: 100%;
        margin: 44px 0 41px;
        font-size: 15px;
        text-align: left;
        overflow: visible;
        @include SP {
          margin: 27px 0 41px;
        }
        .tr {
          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            border-bottom: 1px solid #002e50;
          }
          @include SP {
            flex-wrap: wrap;
          }
          .th {
            @include font(16,34/18,0.1em,bold);
            background-color: #f7f7f7;
            width: 100%;
            max-width: 312px;
            padding-top: 40px;
            padding-left: 33px;
            vertical-align: top;
            border-top-left-radius: 10px;
            @include PC{
              border-bottom-left-radius: 10px;
            }
            @include SP {
              max-width: 100%;
              padding-top: 7px;
              padding-left: 24px;
              padding-bottom: 8px;
              border-top-right-radius: 10px;
              @include font(15,34/15,0.1em, bold);
            }
          }

          .td {
            width: 100%;
            max-width: calc(100% - 312px);
            padding: 31px 26px 14px 26px;
            @include font(16, 32/16, 0.08em, bold);
            position: relative;
            @include SP {
              max-width: 100%;
              padding: 12px 13px 8px;
              @include font(14, 34/14, 0.08em, bold);
            }
            .note {
              @include font(14, 34/14, 0.08em, 500);
              margin-top: 3px;
              color: #92a3b2;
              @include SP {
                letter-spacing: 0.04em;
              }
            }

            span {
              @include font(17, 34/17, 0.04em, 500);
              color: $navy;
            }
          }
        }
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        textarea {

          width: 100%;
          max-width: 574px;
          background-color: #fdfded;
          border: 1px solid #dddddd;
          border-radius: 5px;
          padding: 13px;
          font-size: 16px;
          &.fax-txt {
            max-width: 244.5px;
            margin-bottom: 12px;
            margin-left: 15px;
            @include SP {
              max-width: 168.2px;
              margin-left: 10px;
            }
          }
          @include SP {
            max-width: 100%;
            font-size: 14px;
          }
        }

        textarea {
          height: 174px;
          margin-bottom: 22px;
          @include SP {
            margin-bottom: 15px;
          }
        }
      }
    }
    .confirmform {
      .bthConfirm{
        margin-top:65px;
      }
      .formBlock {
        padding-top: 38px;
        .tableContact {
          margin-bottom: 22px;
          @include SP {
            margin-top: 20px;
            margin-bottom: 17px;
          }
          .th {
            padding: 34px 0 34px 55px;
            display: flex;
            align-items:center;

            @include SP {
              padding: 7px 0 7px 17px;
            }
          }
          .td {
            padding:20px 20px 20px 32px;
            display: flex;
            align-items: center;
            &.address{
              flex-direction: column;
              align-items: flex-start;
            }
            span {
              margin-right: 10px;
            }
            @include SP {
              line-height: calc(28/14);
              padding-top: 16px;
              padding-bottom: 20px;
              padding-left: 18px;
              p{
                line-height: calc(28/14);
              }
            }
          }
        }
      }
      .taR,.taC {
        text-align: right;
        @include font(16, 34/16, 0.1em, bold);
        @include SP {
          @include font(14, 26/14, 0.04em, bold);
        }
        a {
          text-decoration: underline;
          color: #002e50;
        }
      }
      .taC{
        text-align: center;
      }
      .bthConfirm {
        button {
          margin-top: 68px;
        }
      }
    }
  }
  .form-contact {
    position: relative;
    margin-top: 120px;
    @include SP {
      margin-top: 68px;
    }
    &:before {
      content: "";
      position: absolute;
      background: url("../../img/contact/ico-mail.svg");
      width: 80px;
      height: 80px;
      background-size: cover;
      left: calc(50% - 40px);
      top: -40px;
    }

    &::after {
      content: "";
      position: absolute;
      background: #0088db;
      width: 100%;
      height: 642px;
      top: 0;
      z-index: -1;
      @include SP {
        height: 269px;
      }
    }

    &__heading {
      padding: 62px 0 42px;
      @include font(32, 64/32, 0.15em, bold);
      color: #fff;
      text-align: center;
      @include SP {
        @include font(22, 34/22, 0.15em, bold);
        padding: 50px 0 25px;
      }
    }
  }
  .errMsg {
    margin-left: 1em;
    padding-bottom: 1px;
    display: block;
    line-height: 1.4;
    text-indent: -0.9em;
  }

  .formError {
    padding-bottom: 13px;
    display: block;
  }

  .fixed {
    padding-bottom: 0px;
  }

  .formError .formErrorClose {
    border: solid #252525 2px;
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -4px;
    right: -4px;
    color: #efefef;
    background: #333;
    font-weight: bold;
    text-align: center;
    line-height: middle;
    cursor: pointer;
    z-index: 2;
    @include add_prefix(border-radius, 9px 9px 9px 9px);
    @include add_prefix(box-shadow, 1px -1px 3px #888);
  }

  .formError .formErrorClose:hover {
    background: #666;
  }

  .fixed .formErrorClose {
    display: none;
  }

  .formError .formErrorContent {
    padding: 6px 10px;
    width: 180px;
    position: relative;
    color: #fff;
    background: #252525;
    font-family: "Lucida Sans Unicode", "Lucida Grande", Arial, Helvetica,
      "ヒラギノ丸ゴ Pro W4", HiraMaruPro-W4, "ヒラギノ角ゴ Pro W3", "メイリオ",
      Meiryo, Osaka, sans-serif;
    font-size: 11px;
    margin: 0 0;
    z-index: 1;
    @include add_prefix(border-radius, 3px);
    @include add_prefix(box-shadow, 0px 0px 6px #888);
  }

  .fixed .formErrorContent {
    margin-top: 0.5em;
    background: #f60;
    margin: 0 0 30px 0;
    @include add_prefix(border-radius, none);
    @include add_prefix(box-shadow, none);
  }

  .fadeOut {
    opacity: 0.2;
    filter: alpha(opacity=20);
  }

  .formError .formErrorArrow {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 20px;
    margin: 0 0 30px 0;
    z-index: 0;
  }

  .fixed .formErrorArrow {
    display: none;
  }

  .formError .formErrorArrowBottom {
    margin: -6px;
    top: 0;
  }

  .fixed .formErrorArrowBottom {
    display: none;
  }

  .formError .formErrorArrow div {
    margin: 0 auto;
    display: block;
    height: 1px;
    background: #252525;
    line-height: 0px;
    font-size: 0px;
    @include add_prefix(box-shadow, 0px 2px 3px #888);
  }

  .formError .formErrorArrowBottom div {
    @include add_prefix(box-shadow, none);
  }

  .formError .formErrorArrow .line10 {
    width: 19px;
  }

  .formError .formErrorArrow .line9 {
    width: 17px;
  }

  .formError .formErrorArrow .line8 {
    width: 15px;
  }

  .formError .formErrorArrow .line7 {
    width: 13px;
  }

  .formError .formErrorArrow .line6 {
    width: 11px;
  }

  .formError .formErrorArrow .line5 {
    width: 9px;
  }

  .formError .formErrorArrow .line4 {
    width: 7px;
  }

  .formError .formErrorArrow .line3 {
    width: 5px;
  }

  .formError .formErrorArrow .line2 {
    width: 3px;
  }

  .formError .formErrorArrow .line1 {
    width: 1px;
  }
@include PC{
  .design {
    .formError {
      top: 31px !important;
    }
    .chkbox{
      .formError{
        top: 0 !important;
      }
    }
  }
}