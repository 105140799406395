@charset 'UTF-8';
.footer{
  position: relative;
  padding: 0 10px;
  @include SP{padding:0;}
  &:before{
    content: "";position: absolute;
    top:0;left: 0;
    width: 100%;height: 180px;
    background: #fff;
  }
  .wcm{
    max-width: 1264px;
    position: relative;
  }
  &__ctc{
    width: 100%;
    @include flexbox;
    justify-content: space-between;
    flex-wrap: wrap;
    @include screen(768px,1224px){
      width: calc(100% + 20px);
      margin: 0 -10px;
      max-width: 1224px;
    }
    .item{
      width: 50%;
      padding: 66.5px 20px;
      text-align: center;
      color: #fff;
      @include SP{
        width: 100%;
        padding: 32px 22px 36px;
      }
      @include max-screen(370px){padding: 30px 18px 30px;}
      &:nth-child(1){
        background: $navy;
        .c-btn{
          max-width: 235px;
          @include HOVER{
            &:hover{color: $navy;}
          }
        }
      }
      &:nth-child(2){
        background: $blue;
        .c-btn{
          max-width: 288px;
        }
      }
      &__ttl{
        @include font(20,32/20,0.2em,bold);
        padding-left: 5px;
        @include SP{
          font-size: 18px;
          line-height: calc(30/18);
        }
      }
      &__txt{
        @include font(16,28/16,0.08em,bold);
        margin: 39px auto 0;
        @include SP{
          font-size: 14px;
          line-height: calc(24/14);
          margin-top: 12px;
          letter-spacing: 0.1em;
        }
        @include max-screen(370px){font-size: 13px;letter-spacing: 0.05em;}
      }
      .btn-tel{
        display: inline-block;
        color: #fff;
        @include PC{
          padding-left: 47px;
          margin-top: 33px;
          &:before,&:after{
            @include transform_c(scale(1.3));
            margin-left: 6px;
            margin-top: -3px;
          }
          &:before{background: #fff;}
          &:after{background-image: url(../img/common/icon/ico_tel_blue.svg);}
        }
        @include SP{
          width: 100%;
          max-width: 290px;
          margin-top: 23px;
          margin-bottom: 3px;
          color: $navy;
          &:before{margin-left: 0;@include transform_c(scale(1.13));}
          &:after{margin-left: 7px;@include transform_c(scale(1.05));}
          &:before,&:after{
            margin-top: 13px;
            left: calc(50% - 110px);
            @include max-screen(370px){left: calc(50% - 104px)}
          }
        }
        @include max-screen(370px){padding-right: 0;}
        .num{
          font-size: 35px;
          @include SP{font-size: 29px;}
        }
        .time{
          font-size: 13px;
          margin-top: 7px;
          @include SP{font-size: 10px;}
          em{
            font-size: 15px;
            @include SP{font-size: 12px;}
          }
        }
      }
      .c-btn{
        margin: 47px auto 0;
        height: 50px;
        padding-left: 0px;
        padding-right: 37px;
        @include SP{ margin-top: 22px;}
        @include max-screen(370px){
          padding-right: 25px;
          &:before,&:after{@include transform_c(translateX(5px));}
        }
      }
    }
  }
  .page-top{
    opacity: 0;
    cursor: pointer;
    z-index: 999;
    text-align: center;
    width: 65px;
    @include font(12,1,0,300);
    @include barlow;
    white-space: nowrap;
    right: 28px;bottom: -90px;
    position: fixed;
    padding-top: 74px;
    @include transition_c(all 0.3s);
    @include screen(768px,1300px){
      right: 15px;
    }
    @include HOVER{&:hover{
      i{
        background: $navy;
        margin-top: -4px;
        &:after{border-color: #fff;}
        &:before{background: #fff;}
      }
    }}
    @include screen(768px,1200px){
      width: 50px;
      padding-top: 58px;
      font-size: 11px;
    }
    @include SP{right: 30px;}
    @include max-screen(370px){right: 15px;bottom: 15px;}
    i{
      display: block;
      width: 64px;height: 64px;
      position: absolute;
      top:0;left: calc(50% - 32px);
      border-radius: 50%;
      border: 1px solid $navy;
      margin: 1px;
      @include transition_c(all 0.3s);
      @include screen(768px,1200px){
        width: 50px;height: 50px;
        left: calc(50% - 25px);
      }
      &:before,&:after{
        content:"";position: absolute;
        top: calc(50% - 10px);
        @include transition_c(all 0.3s);
      }
      &:before{
        width: 1px;height: 19px;
        background: $navy;
        left: 50%;margin-top: -1px;
      }
      &:after{
        width: 13px;height: 13px;
        border-top: 1px solid $navy;
        border-left: 1px solid $navy;
        left: calc(50% - 6px);
        @include transform_c(rotate(45deg));
      }
    }
  }
  &__ins{
    max-width: 1330px;
    width: 100%;
    padding-bottom: 70px;
    margin: 0 auto;
    @include SP{padding-bottom: 30px;}
  }
  &__copyright{
    position: absolute;
    left: 12px;bottom:24px;
    writing-mode: vertical-rl;
    @include transform_c(rotate(180deg));
    @include font(14,1,0,300);
    @include barlow;
    @include screen(768px,1200px){left: 0;bottom: 12px}
    @include SP{
      position: relative;
      left: 0;bottom: 0;
      writing-mode: inherit;
      @include transform_c(rotate(0));
      padding: 24px 20px 0;
    }
  }
  &__bot{
    max-width: 1024px;
    margin: 71px auto 0;
    @include flexbox;
    justify-content: space-between;
    @include SP{margin-top: 39px;}
  }
  &__info{
    width: 225px;
    @include screen(768px,950px){width: 190px;}
    .logo{
      display: block;
      max-width: 187px;
      margin: -6px 0 22px;
      @include screen(768px,950px){max-width: 160px;}
      @include SP{margin: 0 0 21px;}
      img{width: 100%;}
    }
    .ttl{
      @include font(18,28/18,0.08em,bold);
      @include screen(768px,950px){font-size: 16px;}
      @include SP{}
    }
    .add{
      @include font(14,22/14,0.08em,bold);
      margin-top: 8px;
      width: calc(100% + 10px);
      @include screen(768px,950px){font-size: 12px;}
      a{color: $navy;}
    }
  }
  &__link{
    width: calc(100% - 225px);
    @include flexbox;
    justify-content: flex-start;
    @include screen(768px,950px){width: calc(100% - 190px);padding-left: 5px;}
    .item{
      @include PC{
        &:nth-child(1){
          width: 30.4%;
          @include screen(768px,950px){width: 33.4%;}
        }
        &:nth-child(2){
          width: 27%;
          @include screen(768px,950px){width: 31%;}
        }
        &:nth-child(3){
          width: 42.6%;
          @include screen(768px,950px){width: 35.6%;}
        }
      }
      .hidden{visibility: hidden;pointer-events: none;}
      >li{
        width: 100%;
        >a{
          padding-left: 24px;
          @include font(14,2,0.08em,bold);
          @include HOVER{&:hover{
            text-decoration: underline;
            text-underline-offset: 1px;
            .ico{left: 5px;}
          }}
          @include screen(768px,950px){font-size: 13px;letter-spacing: 0;}
          .ico{
            @include transition_c(left 0.3s);
            position: absolute;
            left: 1px;top: 7px;
          }
        }
        .sub{
          padding-left: 27px;
          margin-top: -1px;
          margin-bottom: 6px;
          li{
            &:not(:last-child){
              margin-bottom: -4px;
            }
          }
          a{
            @include font(12,22/12,0.08em,bold);
            position: relative;
            padding-left: 13px;
            @include HOVER{&:hover{text-decoration: underline;
              text-underline-offset: 1px;}}
            &:before{
              content:"";position: absolute;
              width: 6px;height: 2px;
              background: $neon_green;
              left: 0;top: calc(50% - 1px);
            }
          }
        }
      }
      a{
        display: inline-block;

        color: $navy;
        position: relative;
      }
    }
  }
  &--form{
    background: $navy;
    &:before{content: none;}
    .copyright{
      color: #fff;
      text-align: center;
      @include font(12,1,0.08em,600);
      @include barlow;
      padding: 10.5px 0;
      @include SP{padding: 12px 0 13px;}
    }
  }
}
body.fixHeader{
  .page-top{
    opacity: 1;
    bottom: 30px;
    @include screen(768px,1300px){bottom: 10px;}
  }
}