/*-----------------------------------------------------------
Breadcrumb
------------------------------------------------------------*/
.breadcrumb{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: right;
	margin: -33px auto 0;
	width: calc(100% - 38px);
	max-width: 1360px;
	position: relative;
	z-index: 1;
	padding: 0 0 8px;
	@include SP{
		padding: 0 15px;
		margin: 6px auto 0;
		width: calc(100% - 30px);
		@include multiLineEllipsis(1);
	}
	li{
		display: inline;
		a,span{
			color: #60717C;
			@include font(12,18/12,0.05em,500);
			display:inline;
			vertical-align: top;
			word-break: break-all;
		}
		a{
			text-decoration: underline;
			padding-right: 19px;
			margin-right: 13px;
			position: relative;
			&:after{
				content:"";position: absolute;
				width: 7px;height: 7px;
				border-top: 1px solid #60717C;
				border-left: 1px solid #60717C;
				right: 0;top: calc(50% - 3px);
				@include transform_c(rotate(135deg));
			}
		}
	}
}
