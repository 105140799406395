@charset 'UTF-8';
[rel~="js-lazy"] {
  opacity: 0;
  &:not(.initial) {
    transition: opacity 0.1s;
  }
  &.initial,&.loaded,&.error {
    opacity: 1;
  }
}
.space{
  height: 1775px;
  @include SP{height: 2078px;}
}
/*-----------------------------------------------------------
Page Heading
------------------------------------------------------------*/
.page-heading{
  width: 100%;
  position: relative;
  background: #fff url(../img/common/icon/dots.svg) left top/4px 4px repeat;
  overflow: hidden;
  position: relative;
  @include SP{background-color: #EFF8FF;}
  .wcm{
    padding: 173px 20px 92px;
    max-width: 1208px;
    width: 100%;
    @include screen(768px,1024px){padding: 138px 20px 74px;}
    @include SP{padding: 90px 14px 42px;}
  }
  .jp{
    @include font(24,1.5,0.2em,bold);
    color: $blue;
    position: relative;
    z-index: 1;
    padding-left: 73px;
    @include screen(768px,1024px){font-size: 20px;}
    @include SP{
      font-size: 18px;
      padding-left: 74px;
    }
    &:before{
      content:"";position: absolute;
      width: 53px;height: 1px;
      background: $blue;
      left: 0;top:50%;
    }
  }
  .en{
    color: #fff;
    @include font(229,1,0,300);
    @include barlow;
    white-space: nowrap;
    position: absolute;
    left: calc(50% - 10px);
    width: 100%;
    max-width: 1360px;
    bottom: -33px;
    z-index: 0;
    @include transform_c(translateX(-50%));
    @include screen(768px,1024px){
      font-size: 183px;
      bottom: -26px;left: calc(50% - 8px);
    }
    @include screen(768px,950px){font-size: 150px;bottom: -10px;}
    @include SP{
      font-size: 72px;
      left: 50%;bottom: -5px;
      @include transform_c(translateX(-50%));
      text-align: center;
    }
    @include max-screen(370px){font-size: 66px;bottom: -10px}
  }
}
/*-----------------------------------------------------------
Title, Text
------------------------------------------------------------*/
.ttl-border{
  color: $blue;
  position: relative;
  padding-left: 38px;
  @include screen(768px,1024px){padding-left: 30px;}
  @include SP{padding-left: 28px;}
  &:before{
    content:"";position: absolute;
    width: 8px;height: 72px;
    background: $blue;
    left: 0;top: calc(50% - 34px);
    @include screen(768px,1024px){width: 7px;height: 66px;top: calc(50% - 33px);}
    @include screen(768px,950px){height: 60px;top: calc(50% - 30px)}
    @include SP{
      width: 6px;height: 57px;
      top: calc(50% - 27px);
    }
  }
  .en{
    @include font(64,1,0,300);
    @include barlow;
    @include screen(768px,1024px){font-size: 56px;}
    @include screen(768px,950px){font-size: 49px;}
    @include SP{
      font-size: 40px;
    }
  }
  .jp{
    @include font(14,22/14,0.2em,bold);
    margin: 2px 0 0 4px;
    @include SP{
      font-size: 10px;
      margin: 5px 0 0 3px;
    }
  }
}
/*-----------------------------------------------------------
Button
------------------------------------------------------------*/
.btn-tel{
  padding-left: 35px;
  text-align: left;
  position: relative;
  @include SP{
    width: calc(100% + 5px);
    background: #fff;
    display: block;
    border-radius: 10px;
    color: $navy;
    text-align: center;
    padding: 20px 10px 15px 35px;
    &:before{margin-left: 0;}
    &:after{margin-left: 7px;}
    &:before,&:after{left: calc(50% - 112px);}
    .ins{display: inline-block;text-align: left;}
  }
  &:before,&:after{
    content: "";position: absolute;
  }
  &:before{
    width: 27px;height: 27px;
    background: $navy;
    border-radius: 50%;
    left: 0;top: 10px;
  }
  &:after{
    width: 14px;height: 18px;
    top: 14px;left: 7px;
    background: url(../img/common/icon/ico_tel_white.svg) center/100% 100% no-repeat;
  }
  .num{
    @include font(27,1,0.025em,300);
    @include barlow;
  }
  .time{
    text-align: left;
    @include font(10,1,0.05em,500);
    margin-top: 5px;
    em{
      font-style: normal;
      font-size: 12px;
      margin: 0 0px 0 3px;
    }
  }
}
a.c-btn,.c-btn{
  @include flexbox;
  justify-content: center;
  align-items: center;
  border: 1px solid currentColor;
  border-radius: 50px;
  text-align: center;
  width: 100%;
  max-width: 217px;
  height: 56px;
  @include font(16,30/16,0.1em,bold);
  padding: 11px 30px 13px;
  text-indent: 3px;
  position: relative;
  @include transition_c(all 0.3s);
  @include max-screen(370px){font-size: 14px;}
  &:before,&:after{
    content:"";position: absolute;
    right: 21px;bottom: calc(50% - 2px);
    @include transition_c(right 0.3s);
  }
  &:before{
    width: 10px;height: 2px;
    margin-right: 4px;
    background: currentColor;
  }
  &:after{
    width: 0;height: 0;
    border-bottom: 6px solid currentColor;
    border-right: 6px solid transparent;
  }
  @include HOVER{
    &:hover{
      opacity: 1;
      &:before,&:after{
        right: 17px;
      }
    }
  }
  &--border{
    color: #fff;
    @include HOVER{
      &:hover{
        color: $blue;
        background: #fff;
      }
    }
  }
  &--neon{
    color: $blue;
    background: $neon_yellow;
    @include HOVER{
      &:hover{
        color: $blue;
        background: #fff;
      }
    }
  }
  &--blue{
    color: $navy;
    @include HOVER{
      border-color: $navy;
      &:hover{
        color: #fff;
        background: $navy;
      }
    }
  }
  &--back{
    max-width: 182px;
    height: 50px;
    padding-left: 44px;
    padding-right: 17px;
    justify-content: flex-end;
    &:before,&:after{
      right: auto;
      left: 28px;
      @include transition_c(left 0.3s);
    }
    &:before{
      width: 12px;
      left: 30px;
    }
    &:after{
      transform: scaleX(-1);
    }
    @include HOVER{
      &:hover{
        &:before{
          left: 25px;
        }
        &:after{
          left: 23px;
        }
      }
    }
  }
}
/*-----------------------------------------------------------
List
------------------------------------------------------------*/
i.ico{
  display: block;
  width: 16px;height: 16px;
  border: 1px solid $navy;
  border-radius: 50%;
  position: relative;
  &:before,&:after{
    content:"";position: absolute;
    right: 3px;bottom: calc(50% - 1px);
    @include transition_c(all 0.3s);
  }
  &:before{
    width: 6px;height: 1px;
    margin-right: 2px;
    background: currentColor;
  }
  &:after{
    width: 0;height: 0;
    border-bottom: 4px solid currentColor;
    border-right: 4px solid transparent;
    @include transform_c(scale(0.95));
  }
}
/*-----------------------------------------------------------
Other
------------------------------------------------------------*/


/*-----------------------------------------------------------
Layout has sidebar
------------------------------------------------------------*/
.main_content{
  display: flex;
  max-width: 1140px;
  padding: 0 20px;
  margin: 82px auto 120px;
  &__left{
    flex: 0 0 253px;
    .sidebar{
      max-width: 190px;
      &__block{
        margin-bottom: 52px;
        &__ttl{
         @include font(12,32/12,.08em,700);
         color: $blue;
         background: #F4F6F8;
         border-radius: 10px;
         text-align: center;
         margin-bottom: 20px;
         padding: 6px;
        }
        &__ttl_en{
          @include font(20,32/20,0,300);
          @include barlow;
          color: $blue;
          margin-bottom: 13px;
        }
        &__lst{
          li{
            margin-bottom: 12px;
            a{
              display: block;
              @include font(14,18/14,.025em,700);
              color: $blue;
              position: relative;
              padding-left: 10px;
              &:before{
                content: "";
                position: absolute;
                width: 6px;
                height: 1px;
                background: $neon_green;
                left: 0;
                top: calc(50% - 1px);
              }
            }
          }
        }
        .sel_cat{
          @include font(14,18/14,.025em,700);
          color: $blue;
          @include zen;
          background: #F4F6F8;
          border-radius: 8px;
          padding: 12px 16px;
          @include add_prefix(appearance,none);
          border: none;
          width: 100%;
        }
        &.archive{
          .sidebar__block__lst{
            &>li{
              &>a{
                background: $baby_blue;
                padding: 0 10px;
                @include font(18,33/18,0, 300);
                @include barlow;
                &:before{
                  display: none;
                }
              }
              .sub{
                margin-top: 15px;
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
  &__right{
    width: 100%;
  }
  @include SP{
    flex-direction: column;
    margin: 10px auto 30px;
    &__left{
      flex: none;
      margin-bottom: 27px;
      .sidebar{
        max-width: 280px;
        margin: 0 auto;
        &__block{
          margin-bottom: 4px;
        }
      }
    }
    &__right{
      &.no_img{
        padding-top: 28px;
      }
    }
  }
}

/*-----------------------------------------------------------
Image Thumb
------------------------------------------------------------*/
.thumb {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all .3s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.abs {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/*-----------------------------------------------------------
List Reviews
------------------------------------------------------------*/
.lst_reviews{
  li{
    margin-bottom: 54px;
  }
  &__inner{
    display: flex;
    align-items: flex-start;
    position: relative;
    .img{
      flex: 0 0 48%;
      @include aspect-ratio(408,269);
      border-radius: 20px;
      overflow: hidden;
    }
    .txt{
      margin-top: 21px;
      padding: 53px 39px 78px 32px;
      width: 100%;
      &:before{
        content: '';
        width: 82.5%;
        height: 100%;
        position: absolute;
        top: 21px;
        right: 0;
        background: $bg;
        border-radius: 20px;
        z-index: -1;
      }
      .ico{
        width: 24px;
        height: 24px;
        border: 2px solid $color_body;
        position: absolute;
        right: 27px;
        bottom: 8px;
        transition: transform 0.3s;
        &:before{
          width: 8px;
          height: 2px;
          right: 6px;
          bottom: calc(50% - 2px);
        }
        &:after{
          border-width: 6px;
          right: 3px;
          bottom: calc(50% - 2px);
        }
      }
      .ttl{
        @include font(18,34/18,.08em,700);
        margin-bottom: 20px;
        color: $color_body;
      }
      p{
        @include font(14,26/14,.08em,700);
        color: $color_body;
      }
    }
    &.no_img{
      .txt{
        &:before{
          width: 100%;
          height: 100%;
          top: 20px;
          @include SP{
            top: 0;
          }
        }
      }
    }
    @include HOVER{
      &:hover{
        opacity: .75;
        .txt{
          .ico{
            transform: translateX(4px);
          }
        }
      }
    }
  }
  @include SP{
    li{
      margin-bottom: 20px;
    }
    &__inner{
      flex-direction: column;
      .img{
        flex: none;
        width: 75.5%;
      }
      .txt{
        position: relative;
        margin-left: 20px;
        margin-top: 0;
        padding: 15px 20px 22px 27px;
        width: calc(100% -  20px);
        &:before{
          width: 100%;
          height: calc(100% + 66px);
          top: -66px;
          border-radius: 0 0 0 20px;
        }
        .ico{
          right: 20px;
          bottom: 28px;
        }
        .ttl{
          @include font(16,24/16,.08em,700);
          margin-bottom: 8px;
        }
        p{
          @include font(12,24/12,.08em,700);
          &.txt01{
            font-size: 14px;
          }
        }
      }
    }
  }
}

/*-----------------------------------------------------------
List Blog
------------------------------------------------------------*/
.lst_blog{
  display: flex;
  flex-flow: row wrap;
  margin: 0 -20px;
  li{
    width: 50%;
    padding: 0 20px;
    margin-bottom: 48px;
  }
  &__inner{
    display: block;
    position: relative;
    padding-bottom: 24px;
    border-bottom: 1px solid $blue;
    margin-left: 30px;
    min-height: 100%;
    .img{
      @include aspect-ratio(362,237);
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 6px;
    }
    .date{
      display: flex;
      align-items: flex-start;
      @include barlow;
      @include font(17,1,0,300);
      color: $blue;
      writing-mode: tb;
      transform: rotate(180deg);
      position: absolute;
      top: 9px;
      left: -28px;
      .month{
        @include font-size(26);
      }
      .day{
        @include font-size(46);
        position: relative;
        margin: 15px 0 10px;
        &:before{
          content: '';
          width: calc(100% - 7px);
          height: 1px;
          background: $blue;
          transform: rotate(17deg);
          position: absolute;
          top: -7px;
          left: 2px;
        }
      }
      .year{
        position: relative;
        right: 8px;
      }
    }
    .cate{
      margin-bottom: 8px;
      word-break: keep-all;
      line-height: 1.3;
      &__ttl{
        @include font(12,2,.08em,300);
        @include barlow;
        color: $blue;
        margin-right: 8px;
      }
      &__item{
        @include font(12,2,.08em,700);
        margin-right: 8px;
        color: $color_body;
      }
    }
    .title{
      @include font(16,24/16,.08em,700);
      color: $color_body;
    }
    @include HOVER{
      &:hover{
        opacity: .75;
      }
    }
  }
  @include SP{
    margin: 0;
    li{
      width: 100%;
      padding: 0;
      margin-bottom: 31px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__inner{
      padding-bottom: 22px;
      margin-left: 25px;
      .img{
        margin-bottom: 10px;
      }
      .date{
        @include font(16,1,0,300);
        top: 7px;
        left: -25px;
        .month{
          @include font-size(24);
        }
        .day{
          @include font-size(42);
          margin: 15px 0 10px;
          &:before{
            width: calc(100% - 7px);
            top: -7px;
            left: 2px;
          }
        }
        .year{
          right: 5px;
        }
      }
      .cate{
        margin-bottom: 8px;
        &__ttl{
          @include font-size(14);
          margin-right: 6px;
        }
      }
    }
  }
}

 /*-----------------------------------------------------------
List News
------------------------------------------------------------*/
.lst_news{
  li{
    padding: 34px 0 24px;
    border-bottom: 1px solid #E8E8E8;
    &:nth-child(1){
      padding-top: 24px;
    }
  }
  &__inner{
    display: flex;
    align-items: flex-start;
    .img{
      @include aspect-ratio(144,95);
      border-radius: 8px;
      overflow: hidden;
      flex: 0 0 144px;
    }
    .txt{
      width: 100%;
      padding: 0 31px;
      .meta{
        margin-bottom: 10px;
        .cate{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          @include font(12,2,.08em,700);
          background: #fff;
          color: $blue;
          border: 1px solid $blue;
          border-radius: 16px;
          min-width: 63px;
          padding: 0 8px;
          margin-right: 5px;
          &--event{
            background: $neon_yellow;
          }
        }
        .date{
          @include font(14,1,0,300);
          @include barlow;
          color: $color_body;
        }
      }
      .title{
        @include font(16,24/16,.08em,700);
        color: $color_body;
      }
    }
  }
  @include SP{
    li{
      padding: 24px 0;
      &:nth-child(1){
        padding-top: 0;
      }
    }
    &__inner{
      .img{
        @include aspect-ratio(111,97);
        flex: 0 0 111px;
      }
      .txt{
        padding: 0 0 0 12px;
        .meta{
          margin-bottom: 3px;
          margin-top: -3px;
          .cate{
            line-height: 22px;
          }
          .date{
            font-size: 16px;
          }
        }
        .title{
        }
      }
    }
  }
}

/*-----------------------------------------------------------
List Works
------------------------------------------------------------*/
.lst_works{
  display: flex;
  flex-flow: row wrap;
  margin: 0 23px;
  li{
    width: 50%;
    padding: 0 16px;
    margin-bottom: 45px;
  }
  &__inner{
    display: block;
    .img{
      @include aspect-ratio(495,326);
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 9px;
    }
    .cate{
      margin-bottom: 6px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      &__ttl{
        @include font(14,2,.08em,300);
        @include barlow;
        color: $blue;
        margin-right: 4px;
      }
      &__item{
        @include font(12,2,.08em,700);
        margin-right: 8px;
        color: $color_body;
      }
    }
    .title{
      @include font(16,24/16,.08em,700);
      padding: 0 5px;
      margin-bottom: 8px;
      color: $color_body;
    }
    .work_industry{
      @include font(12,2,.08em,700);
      padding: 0 5px;
      color: $color_body;
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      &__item{
        margin-right: 8px;
      }
    }
    @include HOVER{
      &:hover{
        opacity: .75;
      }
    }
  }
  @include SP{
    margin: 0 -20px;
    li{
      width: 100%;
      padding: 0;
      margin-bottom: 22px;
    }
    &__inner{
      padding: 0 26px 24px;
      position: relative;
      &:before{
        content: '';
        width: 100%;
        height: calc(100% - 120px);
        position: absolute;
        top: 120px;
        left: 0;
        background: $baby_blue;
        z-index: -1;
      }
      .img{
        max-width: 280px;
        margin: 0 auto 10px;
      }
      .cate{
        margin-bottom: 8px;
        &__ttl{
          @include font-size(12);
        }
      }
    }
  }
}

/*-----------------------------------------------------------
Common Pagenavi
------------------------------------------------------------*/
.cmn_pagenavi{
  margin: 105px 0 0;
  font-size: 0;
  line-height: 1;
  text-align: center;
  width: 100%;
  @include SP{
    margin: 51px 0 47px;
  }
  &__prev,
  &__next,
  .wp-pagenavi {
    display: inline-block;
    vertical-align: middle;
    .pages,.nextpostslink,.previouspostslink{
      display: none;
    }
  }
  &__prev,
  &__next {
    // min-width: 51px;
    position: relative;
    a {
      @include PC{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        font-size: 0;
        border: 1px solid $color_body;
        &::before {
          content: '';
          width: 9px;
          height: 9px;
          border-right: 1px solid $color_body;
          border-bottom: 1px solid $color_body;
          position: relative;
        }
        &:hover{
          opacity: 1;
          background: $color_body;
          &::before{
            border-color: #fff;
          }
        }
      }
      @include SP{
        @include font(16,1,.1em,700);
        color: $color_body;
        &::before{
          content: '';
          width: 16px;
          height: 8px;
          background: url(../img/common/icon/ico_next.svg) 0 0/ 100% auto no-repeat;
          position: absolute;
          top: calc(50% - 4px);
        }
      }
    }
    a.disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }
  &__prev {
    margin-right: 15px;

    a {
      &::before {
        @include PC{
          left: 2px;
          transform: rotate(135deg);
        }
      }
      @include PC{
        &:hover{
          transform: translateX(-4px);
        }
      }
    }
    @include SP{
      margin-right: 24px;
      padding-left: 26px;
      a::before{
        left: 0;
        transform: scaleX(-1);
      }
    }
  }
  &__next {
    margin-left: 15px;

    a {
      &::before {
        @include PC{
          right: 2px;
          transform: rotate(-45deg);
        }
      }
      @include PC{
        &:hover{
          transform: translateX(4px);
        }
      }
    }

    @include SP{
      margin-left: 24px;
      padding-right: 26px;
      a::before{
        right: 0;
      }
    }
  }
  .wp-pagenavi {
    a,
    span {
      @include font(16,1,0,300);
      @include barlow;
      width: 38px;
      height: 38px;
      margin: 0 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: transparent;;
      position: relative;
      border-radius: 50%;
      opacity: 1;
      border: none;
    }
    span.current {
      background: $neon_yellow;
      font-size: 14px;
      font-weight: 300;
      color: $color_body;
    }
    span.extend{
      background: transparent;
      letter-spacing: .5px;
      margin-right: 0px;
      margin-left: 2px;
    }
    a.last,a.first{
      display: none;
    }
    @include SP{
      position: relative;
      select{
        border: 1px solid $blue;
        border-radius: 10px;
        width: 115px;
        height: 48px;
        padding: 0;
        @include add_prefix(appearance,none);
        @include font(18,32/18,0,300);
        // text-align-last: center;
        padding-left: 41%;
        @include barlow;
        color: $color_body;
        background: transparent;
      }
    }

    @include PC {
      a:hover{
        background: $baby_blue;
        color: $color_body;
        opacity: .75;
      }
    }
  }
}

/*-----------------------------------------------------------
Common Page Detail
------------------------------------------------------------*/
.page_detail{
  #wrap{
    padding-top: 152px;
  }
  .page_detail_title{
    max-width: 1140px;
    padding: 0 20px;
    margin: 74px auto;
    .title{
      @include font(32,54/32,.08em,700);
      padding-bottom: 40px;
      border-bottom: 4px solid $blue;
    }
  }
  .main_content{
    margin-top: 74px;
    margin-bottom: 97px;
  }
  .main_img{
    margin-bottom: 31px;
    text-align: center;
    img{
      @include PC{
        border-radius: 8px;
      }
    }
  }
  .page_navi_detail{
    padding-top: 57px;
    margin-top: 90px;
    border-top: 1px solid $blue;
    .c-btn{
      margin: 0 auto;
    }
  }
  @include SP{
    #wrap{
      padding-top: 80px;
    }
    .page_detail_title{
      margin: 21px 0 0;
      .title{
        @include font(24,38/24,.08em,700);
        margin: 0 -20px;
        padding: 0 17px 32px 20px;
      }
    }
    .main_content{
      margin-top: 0;
      margin-bottom: 50px;
      &__left{
        display: none;
      }
    }
    .main_img{
      margin: 0 -20px 24px;
    }
    .page_navi_detail{
      padding-top: 42px;
      margin-top: 41px;
    }
  }
}
.ttl_border_left{
  display: flex;
  flex-direction: column;
  color: $blue;
  padding-left: 37px;
  position: relative;
  margin-bottom: 62px;
  &:before{
    content: '';
    width: 8px;
    height: calc(100% - 15px);
    background: $blue;
    position: absolute;
    top: 10px;
    left: 0;
  }
  &__en{
    @include font(64,1,0,300);
    @include barlow;
  }
  &__jp{
    @include font(14,22/14,.2em,700);
    margin-left: 3px;
    margin-top: 2px;
  }
  @include SP{
    padding-left: 27px;
    margin-bottom: 40px;
    &:before{
      top: 2px;
      width: 6px;
      height: calc(100% - 2px);
    }
    &__en{
      @include font-size(40);
    }
    &__jp{
      @include font(10,16/10,.2em,700);
      margin-top: 4px;
    }
  }
}
.ttl_border{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $blue;
  border-radius: 20px;
  position: relative;
  height: 81px;
  max-width: 848px;
  margin: 0 auto 56px;
  padding: 5px 8px;
  &:before{
    content: "";
    position: absolute;
    background-color: #fff;
    border-bottom: 1px solid $blue;
    border-left: 1px solid $blue;
    width: 14px;
    height: 14px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: -8px;
    left: calc(50% - 7px);
  }
  &__inner{
    display: inline-block;
    @include font(20,22/20,.2em,700);
  }

  @include SP{
    border-radius: 10px;
    height: 60px;
    margin-bottom: 23px;

  }
}
.bgDots{
  background: url(../img/common/icon/dots.svg) left top/4px 4px repeat;
}
.bgBlue{
  background: $blue;
}

.c-company{
  width: 100%;
  &__bg{
    display: inline-block;
    width: 100%;
    background: url(../img/company/history/company_bg.jpg) center/cover no-repeat;
    .wcm{
      max-width: 100%;//1184px;
      width: 87%;
      @include screen(768px,950px){width: 96%;padding: 0 20px;}
      @include SP{width: 100%;}
    }
  }
  &__etr{
    @include flexbox;
    justify-content: space-between;
    max-width: 725px;
    width: 100%;
    margin: 52px auto 61px;
    @include SP{
      align-items: flex-start;
      flex-wrap: wrap;
      margin: -8px auto 40px;
      width: 100%;
      max-width: 335px;
    }
    .item{
      width: calc(33.33% - 16.66px);
      display: block;
      text-align: center;
      @include SP{
        width: calc(33.33% - 5.66px);
        margin-bottom: 12px;
      }
      @include HOVER{
        &:hover{
          opacity: 1;
          .thumb:after{opacity: 0.25}
        }
      }
      .thumb{
        @include aspect-ratio(1,1);
        width: 100%;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        &:after{
          content: "";position: absolute;
          width: 100%;height: 100%;
          background: #fff;
          top:0;left: 0;
          @include transition_c(all 0.3s);
          opacity: 0;border-radius: 50%;
        }
      }
      .ttl{
        @include font(18,26/18,0.2em,bold);
        color: #fff;
        margin-top: 18px;
        text-indent: 5px;
        @include SP{
          font-size: 14px;
          letter-spacing: 0;
          text-indent: 0;
          margin-top: 7px;
        }
      }
    }
  }
  &__ttl{
    display: inline-block;
    text-align: right;
    @include SP{
      width: 100%;
      text-align: center;
      margin-left: -20px;
    }
    .en{
      @include font(144,1,-0.001em,300);
      @include barlow;
      color: #3CB5FF;
      margin: -27px 0 -36px -8px;
      @include screen(768px,1024px){
        font-size: 115px;
        margin: -22px 0 -34px -10px;
      }
      @include SP{
        text-align: left;
        font-size: 74px;
        margin: -12px 0 0 0;
      }
      @include max-screen(370px){font-size: 64px;}
    }
    .jp{
      display: inline-block;
      color: #fff;
      @include font(18,1,0.2em,bold);
      padding-left: 66px;
      position: relative;
      @include SP{
        width: calc(100% - 25px);
        font-size: 14px;
        text-align: left;
        margin: 0px 0 0 10px;
        top: -31px;
      }
      &:before{
        content:"";position: absolute;
        width: 53px;height: 1px;
        background: #fff;
        top: calc(50% + 2px);left: 0;
        @include SP{top: 50%;}
      }
    }
  }
  &__btn{
    width: calc(100% - 40px);
    max-width: 527px;
    @include flexbox;
    justify-content: space-between;
    padding: 113px 20px 78px;
    margin:  0 auto;
    @include SP{
      max-width: 258px;
      padding: 54px 0 57px;
      flex-wrap: wrap;
      justify-content: center;
    }
    a{
      width: calc(50% - 15px);
      height: 50px;
      justify-content: flex-start;
      padding-left: 25px;
      &:nth-child(1){
        max-width: 200px;
        @include SP{width: 100%;margin: 0 auto 32px;}
      }
      &:nth-child(2){
        max-width: 218px;
        @include SP{width: 100%;}
      }
    }
  }
}
.c-faq{
  width: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  .item{
    width: 100%;
    &__ques,&__ans .inner{
      position: relative;
      &:before{
        @include barlow;
      }
    }
    &__ques{
      background: #F4F6F8;
      color: $blue;
      @include font(22,32/22,0.08em,bold);
      padding: 20px 20px 19px 92px;
      border-radius: 10px;
      @include screen(768px,950px){
        padding-left: 68px;
        font-size: 20px;
      }
      @include SP{
        font-size: 16px;
        line-height: calc(26/16);
        padding: 15px 45px 15px 55px;
      }
      &:before{
        content:"Q";position: absolute;
        left: 20px;top: -39px;
        @include font(86,1,0.15em,300);
        @include screen(768px,950px){font-size: 73px;top: -27px;left: 12px;}
        @include SP{
          font-size: 71px;
          left: -3px;top: -33px
        }
      }
      &:after{
        content:"";position: absolute;
        top: calc(100% - 1px);left: calc(50% - 15px);
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 18px solid #F4F6F8;
        @include SP{top: calc(100% - 6px)}
      }
      i{
        position: absolute;
        display: inline-block;
        @include font(50,1,0.15em,300);
        @include barlow;
        left: 59px;top: 5px;
        font-style: normal;
        @include screen(768px,950px){
          font-size: 40px;
          left: 44px;top: 12px;
        }
        @include SP{
          font-size: 75px;
          top: -26px;left: 28px;
        }
      }
    }
    &__ans{
      @include font(16,32/16,0.05em,bold);
      @include screen(768px,950px){font-size: 15px;}
      @include SP{
        font-size: 14px;
        line-height: calc(26/14);
        letter-spacing: 0.08em;
      }
      .inner{
        padding: 29px 0 74px 84px;
        @include screen(768px,950px){padding-left: 64px;}
        @include SP{
          padding: 20px 10px 56px 55px;
        }
        &:before{
          content:"A.";position: absolute;
          left: 36px;top: 31px;
          @include font(32,1,0.15em,300);
          color: $neon_green;
          @include screen(768px,950px){font-size: 30px;left: 24px}
          @include SP{
            left: 10px;top: 20px;
          }
        }
        +img{
          margin-top: -14px;
          margin-bottom: 78px;
          @include SP{
            margin: -34px auto 55px;
            display: block;
            max-width: calc(100% - 20px);
          }
        }
      }
    }
    &__img{
      margin-top: -10px;
    }
  }
}
.staff-lst{
  width: 100%;
  @include flexbox;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item{
    width: calc(50% - 15.5px);
    @include flexbox;
    justify-content: space-between;
    padding-bottom: 20px;
    position: relative;
    @include screen(768px,950px){padding-bottom: 10px;}
    @include SP{
      width: 100%;
    }
    @include HOVER{
      &:hover{
        opacity: 0.75;
        .ico{right: 12px;}
      }
    }
    &:before{
      content:"";position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      top: 20px;left: 20px;
      background: $baby_blue;
      border-radius: 10px;
      @include SP{
        top: 23px;
      }
    }
    &__img{
      width: 52.7%;//171px;
      position: relative;
      border-radius: 10px;
      background: #fff ;//url(../img/common/icon/dots.svg) left top/4px 4px repeat;
      @include SP{width: 198px;}
      @include max-screen(370px){width: 150px;}
      .thumb{
        width: 100%;
        @include aspect-ratio(171,189);
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        @include SP{
          &:before{padding-top: (220/198)*100%}
        }
      }
    }
    &__info{
      width: calc(100% - 52.7%);
      position: relative;
      padding: 48px 20px 12px 20px;
      color: $navy;
      @include screen(768px,950px){
        padding: 30px 10px 20px 10px;
      }
      @include SP{
        width: calc(100% - 198px);
        padding: 53px 20px 20px 23px;
      }
      @include max-screen(370px){
        width: calc(100% - 150px);
        padding: 45px 20px 15px 20px
      }
      .name1{
        @include multiLineEllipsis(1);
      }
      .name1{
        @include font(12,25/12,0.08em,bold);
        @include SP{font-size: 14px;}
      }
      .name2{
        @include font(16,26/26,0.08em,bold);
        margin-top: 2px;
        @include screen(768px,950px){font-size: 14px;}
        @include SP{
          font-size: 18px;
          margin-top: 5px;
        }
      }
      .des{
        width: calc(100% + 3px);
        @include font(12,22/12,0.08em,bold);
        border-top: 1px solid $blue;
        padding-top: 8px;
        margin-top: 12px;
        @include multiLineEllipsis(3);
        max-height: 74px;
        @include screen(768px,950px){
          font-size: 11px;
          line-height: 18px;
          max-height: 62px;
        }
        @include SP{
          font-size: 14px;
          line-height: calc(22/14);
        }
      }
      .ico{
        @include transition_c(all 0.3s);
        position: absolute;
        right: 15px;bottom: -5px;
        width: 20px;height: 20px;
        border-width: 2px;
        background-color: $baby_blue;
        @include screen(768px,950px){bottom: -2px;right: 10px;}
        @include SP{
          width: 23px;height: 23px;
          right: 18px;bottom: 1px;
        }
        @include max-screen(370px){bottom: -10px;}
        &:before{
          height: 2px;
          width: 9px;
          margin-right: 1px;
          bottom: calc(50% - 2px);
          @include SP{width: 12px;}
        }
        &:after{
          right: 2px;
          border-width: 6px;
          bottom: calc(50% - 2px);
        }
      }
    }
  }
  &--02{
    .item{
      margin-bottom: 52px;
      @include PC{
        &:before{
          top: 25px;
          left: 25px;
          width: calc(100% - 25px);
        }
        &__info{
          padding: 61px 24px 15px 26px;
          .name1{font-size: 14px;}
          .name2{font-size: 20px;margin-top: 5px;}
          .des{font-size: 12px;}
        }
        .ico{
          bottom: 0px;right: 20px;
          width: 26px;height: 26px;
          &:before{
            width: 14px;
          }
          &:after{
            right: 3px;
          }
        }
      }
      @include SP{
        margin-bottom: 24px;
        .ico{right: 22px;}
      }
      @include max-screen(370px){.ico{right: 16px}}
      &:not(:nth-child(2n)){
        margin-right: 31px;
        @include SP{margin-right: 0;}
      }
    }
  }
}

.sec-related{
  padding-bottom: 126px;
  @include SP{padding-bottom: 67px;}
  .wcm{max-width: 1064px;}
  .inner{
    border-top: 1px solid $blue;
    max-width: 935px;
    margin: 2px auto 0;
    width: 100%;
    @include SP{
      margin: 4px auto 0;
    }
  }
  .c-btn{
    margin: 56px auto 0;
    height: 50px;
    width: 182px;
    justify-content: flex-end;
    padding-right: 20px;
    @include SP{
      margin: 32px auto 0;
      padding-right: 26px;
    }
    &:before{
      right: calc(100% - 46px);
      @include SP{right: calc(100% - 42px);}
    }
    &:after{
      right: calc(100% - 34px);
      @include transform_c(rotateY(180deg));
      @include SP{right: calc(100% - 30px);}
    }
    @include HOVER{&:hover{
      &:after{right: calc(100% - 30px);}
      &:before{right: calc(100% - 42px);}
    }}
  }
  .ttl-border{
    margin: 103px auto 45px;
    @include SP{margin: 84px auto 50px;}
  }
  .staff-lst{
    @include SP{
      width: calc(100% + 40px);
      margin: 0 -20px;
    }
    .item{
      width: calc(33.33% - 16.66px);
      position: relative;
      @include screen(768px,950px){
        width: calc(33.33% - 10px);
      }
      @include SP{
        width: 100%;
        margin-bottom: 24px;
      }
      &:not(:nth-child(3n)){
        margin-right: 25px;
        @include screen(768px,950px){margin-right: 15px;}
        @include SP{margin-right: 0;}
      }
    }
  }
}
.sec-services{
  width: 100%;
  .bgDots{
    width: 100%;
    display: inline-block;
    position: relative;
    @include SP{
      padding-bottom: 70px;
    }
  }
  .ttl-border{
    margin-left: -20px;
  }
  .btn-right{
    padding-top: 68px;
    max-width: 390px;
    @include SP{
      max-width: 100%;
      padding-top:0;
      top: calc(100% + 30px);
      padding-left: 20px;
    }
  }
  .wcm{
    width: calc(100% - 40px);
    max-width: 1224px;
    background: #fff;
    border-radius: 20px;
    padding: 44px 20px 90px;
    position: relative;
    margin: 0 auto;
    @include SP{
      border-radius: 10px;
      width: calc(100% - 20px);
      padding: 30px 20px 38px;
    }
  }
  .container{
    margin: 70px auto 165px;
    position: relative;
    @include SP{margin: 12px auto 0;}
    a{
      display: block;
      @include SP{
        width: 100%;
      }
      &:not(:last-child){
        margin-bottom: 72px;
        @include SP{margin-bottom: 25px;width: calc(100% + 1px);}
      }
      img{width: 100%;}
    }
  }
  .services-lst{
    width: 100%;
    max-width: 1024px;
    border: 1px solid #BBCBD9;
    border-radius: 20px;
    @include flexbox;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px auto 0;
    position: relative;
    @include SP{
      border: none;
      border-radius: 0;
      margin: 24px auto 0;
      padding: 0 5px 0 10px;
    }
    @include max-screen(370px){padding: 0;}
    &:before,&:after{
      content:"";position: absolute;
      background: #BBCBD9;
      width: 1px;height: 100%;
      top:0;
      @include SP{content: none;}
    }
    &:before{left: 33.3%;}
    &:after{right: 33.2%;}
    .item{
      width: 33.33%;
      text-align: center;
      padding: 24px 20px;// 31px;
      @include HOVER{
        &:hover{
          opacity: 0.75;
          .ico{margin-right: -4px;}
        }
      }
      @include SP{
        width: 100%;
        border-bottom: 1px solid #BBCBD9;
        @include flexbox;
        justify-content: space-between;
        align-items: center;
        padding: 14px 0;// 3px 0;
        &:first-child{
          border-top: 1px solid #BBCBD9;
        }
      }
      .thumb{
        display: block;
        width: 100%;
        max-width: 241px;
        @include aspect-ratio(241,163);
        background-position: center;
        background-size: cover;
        display: inline-block;
        border-radius: 10px;
        overflow: hidden;
        @include SP{
          width: 132px;
          &:before{content: none;}
        }
      }

      img{
        width: 100%;height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top:0;left: 0;
        border-radius: 10px;
        @include SP{
          position: relative;
          width: 100%;
          object-fit: inherit;
        }
      }
      // img{
      //   width: 100%;
      //   max-width: 241px;
      //   @include SP{width: 132px;}
      //   @include max-screen(370px){width: 120px;}
      // }
      .ttl{
        @include font(20,1.5,0.1em,bold);
        color: $navy;
        width: 100%;
        max-width: 241px;
        margin: 0 auto;
        text-align: left;
        position: relative;
        min-height: 60px;
        @include flexbox;
        justify-content: flex-start;
        align-items: center;
        padding-right: 30px;
        @include screen(768px,950px){font-size: 17px;}
        @include SP{
          width: calc(100% - 132px);
          font-size: 16px;
          line-height: calc(28/16);
          padding: 10px 35px 10px 15px;
        }
        @include max-screen(370px){
          padding-right: 30px;
          width: calc(100% - 120px);
          font-size: 14px;
          padding-left: 10px;
        }
        .ico{
          position: absolute;
          right: calc(50% - 114px);
          width: 24px;height: 24px;
          border-width: 2px;
          top: calc(50% - 9px);
          @include transition_c(all 0.3s);
          @include screen(768px,950px){right: calc(50% - 100px);}
          @include SP{
            right: 7px;
            top: calc(50% - 12px);
          }
          @include max-screen(370px){right: 0;}
          &:before,&:after{
            bottom: calc(50% - 2px);
          }
          &:before{
            height: 2px;width: 11px;
          }
          &:after{border-width: 6px;}
        }
      }
      @include PC{
        margin-bottom: -4px;
        border-top: 1px solid #BBCBD9;
        &:nth-child(1),&:nth-child(2),&:nth-child(3){
          margin-bottom: 0;
          border-top: none;
          .ico{top: calc(50% - 6px);}
        }
      }
    }
  }
}
.flow-lst{
  .item{
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #BBCBD9;
    @include SP{
      min-height: 88px;
      align-items: flex-start;
      flex-wrap: wrap;
      &:not(:last-child){margin-bottom: 19.75px;}
    }
    &__ttl{
      width: 400px;
      @include font(20,32/20,0.1em,bold);
      color: $blue;
      position: relative;
      padding-left: 87px;
      padding-right: 30px;
      @include screen(768px,950px){
        width: 320px;
        font-size: 17px;
        padding-left: 80px;
      }
      @include SP{
        width: 100%;
        font-size: 18px;
        line-height: calc(30/18);
        padding-left: 92px;
        letter-spacing: 0.2em;
        &.no_text{
          padding-top: 18px;
        }
      }
      @include max-screen(370px){font-size: 16px;}
      .num{
        position: absolute;
        top: calc(50% - 30px);left: 8px;
        min-width: 64px;height: 64px;
        border: 1px solid $blue;
        border-radius: 50%;
        text-align: center;
        @include font(30,1,0,300);
        @include barlow;
        color: $blue;
        font-style: normal;
        padding: 20px 5px 0;
        display: inline-block;
        @include screen(768px,950px){left: 0;}
        @include SP{
          left: 10px;top: 2px;
        }
        &:before{
          content:"STEP";position: absolute;
          top: 11px;left: 0;
          width: 100%;
          @include font(9,1,0,300);
          @include barlow;
          color: $blue;
          @include SP{
          }
        }
      }
    }
    &__txt{
      width: calc(100% - 400px);
      @include font(14,26/14,0.08em,bold);
      @include flexbox;
      justify-content: flex-start;
      align-items: center;
      min-height: 92.5px;
      padding-bottom: 18px;
      padding-top: 18px;
      @include screen(768px,950px){
        width: calc(100% - 320px);
        letter-spacing: 0.05em;
        font-size: 13px;
      }
      @include SP{
        width: 100%;
        min-height: 1px;
        padding: 5px 20px 18px 92px;
      }
      @include max-screen(370px){font-size: 13px;}
    }
  }
}


//slick slider sp
.slider_for_sp{
  @include SP{
    width: auto !important;
    display: block !important;
    margin: 0 -20px 0 0 !important;
    padding-right: 57px;
    padding-bottom: 65px;
    overflow: hidden;
    // Fix slick line-clamp
    .slick-loaded .slick-slide > div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .slick-list{
      overflow: visible;
      .slick-slide{
        margin-right: 20px;
      }
    }
    // css slick arrow, dots
    .slick-arrow{
      position: absolute;
      bottom: 0;
      top: auto;
      width: 20px;
      height: 36px;
      background: none;
      appearance: none;
      box-shadow: none;
      border: none;
      border-radius: 0;
      padding:0;margin: 0;
      @include font(0,0,0);
      z-index:9;
      &:before{
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        border-top: 1px solid $blue;
        border-right: 1px solid $blue;
        top:5px;
        opacity: 1;
        @include transition_c(all 0.3s);
      }
      &.slick-disabled{opacity: 0.75;}

      &.slick-prev{
        left: calc(50% - 169px);
        &:before{
          left: -5px;
          @include transform_c(rotate(-135deg));
        }
      }
      &.slick-next{
        right: calc(50% - 169px);
        &:before{
          right: 15px;
          @include transform_c(rotate(45deg));
        }
      }
      @include max-screen(374px) {
        &.slick-prev{
          left: 9px;
        }
        &.slick-next{
          right: 9px;
        }
      }
    }
    .slick-dots{
      @include flexbox;
      justify-content: center;
      width: 100%;
      max-width: 274px;
      z-index: 9;
      position: absolute;
      bottom: 38px;
      left: calc(50% - 151px);
      li{
        padding: 0;
        margin: 0 10px;
        width: 24px;height: 2px;
        appearance: none;
        box-shadow: none;
        border: none;
        border-radius: 0;
        background: #BBCBD9;
        margin-bottom: 0;
        @include font(0,0,0);
        button{
          text-indent: -9999px;
        }
        &.slick-active{
          background: $neon_green;
        }
      }
      @include max-screen(374px) {
        max-width: 220px;
        left: calc(50% - 124px);
      }
    }
  }
}