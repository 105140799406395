@charset 'UTF-8';
.header{
  width: 100%;
  position: fixed;
  top:0;left: 0;
  z-index:99999;
  @include font(0,0,0);
  @include transition_c(background 0.3s);
  &__ins{
    width: 100%;
    @include flexbox;
    justify-content: space-between;
    align-items: center;
    padding: 10px 99px 10px 25px;
    @include SP{padding: 15px 9px 15px 15px;}
  }
  &__logo{
    width: 111px;height: 45px;
    @include SP{width: 103px;}
    a{
      display: block;
      width: 100%;height: 100%;
      img{
        max-width: 111px;
        width: 100%;
        backface-visibility: hidden;
        @include SP{height: 42px;width: 103px;}
      }
    }
  }
  &__ctc{
    width: calc(100% - 111px);
    @include flexbox;
    justify-content: flex-end;
    padding: 0 24px;
    li{
      display: block;
      vertical-align: top;
      border-right: 1px solid #92A3B2;
      a{
        display: block;
        text-align: center;
        color: $navy;
        .img{
          display: inline-block;
          width: 50px;
          height: 32px;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
        }
        .txt{
          @include font(14,1,0,bold);
          text-align: center;
          margin-top: 6px;
        }
        &:not(.btn-tel){
          padding-bottom: 11px;
        }
        &.btn-tel{
          padding: 8px 23px 0 35px;
        }
      }
      &:nth-child(2){
        a{
          padding-left: 22px;
          padding-right: 15px;
        }
        .img{
          background-size: 26px 21px;
          right: 5px;top:1.5px;
        }
      }
      &:nth-child(3){
        a{
          padding-left: 15.5px;
          padding-right: 12px;
        }
        .img{
          background-size: 32px 26px;
          right: 2px;
        }
        .txt{letter-spacing: 0.05em;text-indent: 2px;}
      }
    }
  }
  .hamburger{
    width: 88px;
    height: 88px;
    border-radius: 10px;
    background: $navy;
    color: #fff;
    position: fixed;
    right: 11px;top: 10px;
    cursor: pointer;
    z-index:999999;
    @include HOVER{
      @include transition_c(all 0.3s);
      &:hover{
        opacity: 0.75;
      }
    }
    @include SP{
      right: 9px;top: 7px;
      border-radius: 5px;
      width: 59px;height: 59px;
    }
    &:before{
      content:"メニュー";position: absolute;
      bottom: 19px;left: 0;
      width: 100%;text-align: center;
      color: #fff;
      @include font(12,1,0,bold);
      @include transition_c(all 0.3s);
      @include SP{
        font-size: 10px;
        bottom: 11px;
      }
    }
    .line{
      display: block;
      width: 38px;height: 1px;
      background: transparent;
      margin: 43px auto 0;
      position: relative;
      @include SP{
        width: 25px;
        margin-top: 29px;
      }
      &:before,&:after{
        content:"";position: absolute;
        width: 100%;height: 1px;
        background: #fff;
        top: -12px;left: 0;
        @include transition_c(all 0.3s);
      }
      &:before{
        top: -12px;
        @include SP{top: -8.5px}
      }
      &:after{
        top:0;
      }
    }
  }
  &__menu{
    position: fixed;
    width: calc(100% - 50px);
    max-width: 680px;
    height: 100%;
    top:0;right: -680px;
    opacity: 0;
    z-index:99999;
    background: #009EFF;
    color: #fff;
    padding: 38px 20px;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
    -o-overflow-scrolling: touch;
    overflow-scrolling: touch;
    -ms-overflow-style: none;
    -ms-scrollbar-width: none;
    overflow: auto;
    scrollbar-width: none;
    @include transition_c(all 0.4s);
    &::-webkit-scrollbar { display: none;}
    @include SP{
      right: calc(-100% + 35px);
      top: 75px;
      width: calc(100% - 35px);
      height: calc(100% - 72px);
      padding: 36px 25px 0;
    }
    @include max-screen(370px){padding: 30px 15px 0;}
    &.active{
      right: 0;
      opacity: 1;
    }
    .inner{
      width: 100%;
      max-width: 590px;
      margin: 0 auto;
      position: relative;
      padding: 95px 0 67px;
      @include SP{
        padding: 0 0 42px;
      }
    }
    .btn-close01{
      cursor: pointer;
      position: absolute;
      width: 34px;height: 34px;
      top:0;right: -6px;
      @include SP{display: none;}
      &:before,&:after{
        content:"";position: absolute;
        width: 100%;height: 1px;
        background: #fff;
        top:0;left: 0;
      }
      &:before{
        @include transform_c(rotate(135deg) translate(11px,-11px) scale(1.3));
      }
      &:after{
        @include transform_c(rotate(45deg) translate(11px,11px) scale(1.3));
      }
    }
    .btn-close02{
      cursor: pointer;
      display: block;
      margin: 0 auto;
      width: 50px;
      @include font(16,1,0.025em,300);
      @include barlow;
      padding-top: 30px;
      position: relative;
      white-space: nowrap;
      @include SP{margin-bottom: 40px;}
      &:before,&:after{
        content:"";position: absolute;
        width: 100%;height: 2px;
        background: #fff;
        top:-6px;left: 0;
      }
      &:before{
        @include transform_c(rotate(135deg) translate(11px,-11px) scale(0.5));
      }
      &:after{
        @include transform_c(rotate(45deg) translate(11px,11px) scale(0.5));
      }
    }
    .link{
      display: block;
      a{
        color: #fff;
        position: relative;
        @include font(14,19/14,0.08em,bold);
        display: block;
        padding-left: 30px;
        &:before,&:after{
          content:"";position: absolute;
          right: calc(100% - 18px);bottom: calc(100% - 12px);
          @include transition_c(all 0.3s);
        }
        &:before{
          width: 5px;height: 1px;
          margin-right: 3px;
          background: currentColor;
        }
        &:after{
          width: 0;height: 0;
          border-bottom: 4px solid currentColor;
          border-right: 4px solid transparent;
        }
      }
    }
    .gr-twocolumn{
      width: 100%;
      @include flexbox;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        width: calc(50% - 10px);
        @include SP{width: 100%;}
      }
    }
    .gr{
      width: 100%;
      @include flexbox;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .gr01{
      width: calc(100% - 10px);
      margin: 0 auto;
      li{
        @include SP{width: 100%;}
        &:nth-child(1) .gr{
          @include PC{
            padding-left: 29px;
            li{
              &:nth-child(2n+1){width: 48.8%;}
              &:nth-child(2n){width: 51.2%;}
            }
          }
        }
        &:nth-child(2) .gr{
          @include PC{
            padding-left: 22px;
            li{
              &:nth-child(2n+1){width: 48%;}
              &:nth-child(2n){width: 52%;}
            }
          }
        }
        .gr a{
          display: inline-block;
          color: #fff;
          position: relative;
          @include font(14,19/14,0.08em,bold);
          display: block;
          padding-left: 23px;
          margin-bottom: 10px;
          @include HOVER{
            &:hover{
              opacity: 1;
              .ico{left: 4px;}
            }
          }
          .ico{
            border-color: #fff;
            position: absolute;
            left: 0;top: 2.5px;
            @include transition_c(left 0.3s);
          }
        }
      }
      .c-btn{
        max-width: 100%;
        margin-bottom: 16px;
        @include SP{margin-bottom: 12px;}
      }
      .link{
        margin-bottom: 9px;
        @include SP{margin-bottom: 0;}
      }
    }
    .gr02{
      margin-top: 23px;
      @include SP{margin-top: 8px;}
      a{
        @include flexbox;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        width: calc(50% - 7px);
        border-radius: 10px;
        background: #fff;
        overflow: hidden;
        @include SP{width: 100%;margin-bottom: 10px;}
        .img{
          width: 128px;
          @include aspect-ratio(128,88);
          background-size: cover;
          background-position: center;
        }
        .txt{
          width: calc(100% - 128px);
          color: $blue;
          padding: 0 20px 0 10px;
          @include font(16,30/16,0.1em,bold);
          @include SP{padding-right: 10px;}
          @include max-screen(370px){font-size: 15px;}
        }
      }
    }
    .gr03{
      margin-top: 13px;
      @include SP{margin-top: 10px;}
      a{
        display: block;
        width: calc(33.33% - 10px);
        border-radius: 10px;
        background: #E7F0F7;
        overflow: hidden;
        padding: 8px 10px;
        text-align: center;
        @include SP{
          width: calc(50% - 5px);
          margin-bottom: 9px;
        }
        @include max-screen(370px){padding: 10px 10px 6px;}
        img{
          width: 36px;height: 36px;
        }
        .txt{
          @include font(14,18/14,0.1em,bold);
          min-height: 37px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $blue;
          @include max-screen(370px){font-size: 13px;min-height: 32px;margin-top: 5px;}
        }
      }
    }
    .gr04{
      margin-top: 42px;
      @include SP{margin-top: 13px;}
      li{
        text-align: center;
        @include SP{
          &:nth-child(2){padding-left: 5px;margin-top: 8px;}
        }
      }
      .link{
        margin-top: 2px;
        margin-bottom: 12px;
        padding: 0 2px;
        text-align: left;
        @include SP{
          padding: 0;
          margin-bottom: 15px;
        }
        a{
          border-bottom: 1px solid #fff;
          padding-bottom: 11px;
          padding-left: 11px;
          letter-spacing: 0.1em;
          @include HOVER{
            &:hover{
              opacity: 1;
              &:before,&:after{right: 15px;}
            }
          }
          @include SP{padding-bottom: 12px;}
          &:before,&:after{
            right: 19px;
            @include SP{right: 8px;}
          }
          &:before{
            width: 10px;height: 2px;
            margin-right: 4px;
          }
          &:after{
            border-bottom: 6px solid currentColor;
            border-right: 6px solid transparent;
          }
        }
      }
      .btn-tel{
        color: #fff;
        display: inline-block;
        margin-top: 13px;
        &:before,&:after{
          margin: -5px 0 0 -3px;
          @include transform_c(scale(1.15));
          @include SP{margin-top:15px}
        }
        @include SP{
          margin: 24px 0 0 -5px;
          padding-bottom: 21px;
          width: calc(100% + 5px);
          color: $navy;
          &:before{margin-left: 0;}
          &:after{margin-left: 7px;}
          &:before,&:after{
            left: calc(50% - 112px);
          }
        }
        @include PC{
          &:before{background: #fff;}
          &:after{background-image: url(../img/common/icon/ico_tel_blue.svg);}
        }
        .num{font-size: 29px;}
      }
      .c-btn{
        max-width: calc(100% - 5px);
        padding-left: 10px;
        border-color: $neon_yellow;
        margin-bottom: 16px;
        @include HOVER{&:hover{border-color: #fff;}}
        @include SP{
          &:before,&:after{right: 15px;}
        }
      }
      .bnr{
        display: block;
        max-width: 280px;
        margin: -101px auto 0 2px;
        @include SP{
          margin: 23px auto 0;
          max-width: 100%;
        }
        img{width: 100%;}
      }
    }
    .gr05{
      @include SP{
        padding: 0 22px;
        li{
          width: 50%;
        }
      }
    }
  }
  &.menu-open{
    @include SP{background: #fff;}
    .hamburger{
      @include PC{background: transparent;}
      &:before{color: transparent;}
      .line{
        &:before{
          @include transform_c(rotate(-45deg) translate(-10px,8px) scale(1.1));
          @include SP{
            @include transform_c(rotate(-45deg) translate(-6px,4.5px) scale(1));
          }
        }
        &:after{
          @include transform_c(rotate(45deg) translate(0.5px,1px) scale(1.1));
          @include SP{
            @include transform_c(rotate(45deg) translate(-1px,0px) scale(1));
          }
        }
      }
    }
  }
}
html,body{
  -ms-scrollbar-width: none;
  scrollbar-width: none;
}
body{

  &.no-scroll{
    overflow: hidden;
  }
  &.fixHeader{
    .header{
      background: #fff;
    }
  }
}